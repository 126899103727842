@import "../variables";
@import "../mixins";

$newsroom-indent: 70px;

html[lang="ps"],
html[lang="ur"] {
  .translated {
    direction: rtl;
  }
}

body.newsroom {
  h2 {
    font-family: $font-family-serif;
    font-weight: bold;
    line-height: 1.25em;
    color: $malala;
    margin-bottom: $gutter-width;
  }

  .newsroom__hero {
    position: relative;
    padding: 50px 0;
    background-color: $cobalt-3;

    .hero__inner {
      min-height: 340px;
      display: flex;
      flex-direction: column;
    }

    .hero__heading {
      margin-top: 0px;
    }

    .hero__content {
      width: calc(100% - 532px);
      min-width: 70%;
      flex-grow: 1;
      height: 100%;
    }

    .hero__lead {
      max-width: 586px;
      font-size: $font-size-h1;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;

      @media (max-width: $media-md-max) {
        font-size: $font-size-h2;
        line-height: 40px;
      }
    }

    .hero__footer {
      margin-top: 30px;

      @media (min-width: $media-md) {
        padding-left: 55px;
      }
    }

    .accents {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      max-width: calc(50% - 25px);
      width: 100%;
      overflow: hidden;

      @media (min-width: $media-lg) {
        .accent {
          position: absolute;
          top: 20px;
          bottom: 20px;
          left: 0;
          width: 100%;
          transform: translateX(40%);
          height: calc(100% - 40px);
        }
      }

      @media (max-width: $media-md-max) {
        .accent {
          position: absolute;
          bottom: 50px;
          right: 0;
          transform: translateX(35%);
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  .hero .button--link {
    color: $cobalt;
  }

  .button--link.newsroom__back {
    text-decoration: underline;
    font-size: 12px;

    &:hover {
      text-decoration: none;
      color: $cobalt-3;
    }
  }

  .search__controls {
    @media (max-width: $media-sm-max) {
      margin-bottom: 25px;
    }

    @media (min-width: $media-md) {
      margin-bottom: 70px;
    }
  }

  .newsroom__search-wrapper {
    position: sticky;
    z-index: 100;
    top: $mobile-header-height;
    background: $white;
    @media (max-width: $media-md-max) {
      top: $header-height;
    }
    @media (min-width: $media-lg) {
      top: $header-height;
    }
  }

  .post-carousel {
    --swiper-pagination-color: $peach;
    .card {
      margin-bottom: 30px;
    }
    .swiper-slide {
      opacity: 1;
    }
    .swiper-pagination-bullet {
      background-color: $white;
      opacity: 1;
      width: 8px;
      height: 8px;

      &.swiper-pagination-bullet-active {
        background-color: $peach;
      }
    }
  }

  .newsroom__search-container {
    @media (max-width: $media-md-max) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @media (max-width: $media-md-max) {
    .search-bar__wrapper {
      background-color: white;
      overflow-y: auto;
      max-height: calc(100vh - #{$mobile-header-height} - 40px);
      padding: 25px 0 5px;
      max-width: calc(100vw);
      margin: 0 auto;
    }
  }

  @media (min-width: $media-lg) {
    .search-bar__placeholder {
      display: none;
    }
  }


  .newsroom__search-bar {
    .search__bar,
    .search__filters {
      @media (min-width: $media-lg) {
        border: solid 1px $warm-gray;
        padding: 10px 25px 10px 10px;
        box-shadow: 0 1px 2px 0 #e5e4e3;
      }
    }

    .search__bar {
      display: flex;
      justify-content: space-between;
    }

    .search__bar .preloader {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 20px;
    }

    .search__bar fieldset {
      flex-grow: 1;
      border: none;
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      max-width: 690px;

      @media (max-width: $media-sm-max) {
        padding-bottom: 0;
        padding-left: 0;
        border-bottom: solid 1px $cool-gray;
      }
    }

    .search__icon {
      @media (max-width: $media-sm-max) {
        margin: 7px 0;
      }
    }

    .search__field {
      border: 0;
      border-radius: 0px;
      box-shadow: none;
      font-size: $font-size-tiny;
      font-weight: $font-weight-bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: 1px;
      text-transform: uppercase;
      width: 100%;

      @media (min-width: $media-md) {
        padding-right: 50px;
      }

      @media (max-width: $media-sm-max) {
        padding: 0 14px;
      }

      &::placeholder {
        opacity: 0.6;
        font-style: normal;
      }

      &:focus {
        outline: none;
      }

      @media (min-width: $media-md) {
        border-bottom: solid 1px $cool-gray;
      }
    }

    .search__filters-toggle {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .search__filters-toggle .button--link {
      @media (max-width: $media-sm) {
        display: none;
      }
    }

    .search__filters-toggle:hover .button--link {
      text-decoration: underline;
    }

    .search__filters-icon {
      margin: 0 10px 0 15px;
      width: 29px;
      display: flex;
      justify-content: center;

      @media (max-width: $media-md-max) {
        margin: 0 0 0 15px;
        transform: translateX(2px);
      }
    }

    .search__close-icon {
      @include position-right(25px);
      cursor: pointer;
      @media (max-width: $media-sm-max) {
        display: none;
      }
    }

    .filters__backdrop {
      @media (max-width: $media-sm-max) {
        z-index: 99;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: calc(var(--vh, 1vh) *  100);
      }
    }

    .search__filters {
      padding: 25px 25px 25px 64px;

      @media (max-width: $media-md-max) {
        transition: transform .5s ease-in-out;
        transform: translateY(calc( var(--vh, 1vh) *  100 ));
        padding-left: 34px;
        padding-right: 34px;
        max-width: calc(100vw - 30px);
        margin: 0 auto;
        position: fixed;
        max-height: calc(var(--vh, 1vh) *  100 - #{$mobile-header-height} - 100px);
        right: 0;
        left: 0;
        bottom: 0;
        background-color: $white;
        z-index: 100;
        overflow-y: auto;
      }


      .filters__list {
        display: flex;
        justify-content: flex-start;
      }

      .filters__heading {
        color: #A2A3A4;
        text-transform: uppercase;
        font-size: $font-size-tiny;
        font-weight: $font-weight-black;
        letter-spacing: 1px;
        white-space: nowrap;
      }

      .filters__bar {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
      }

      .filters__close {
        cursor: pointer;
      }

      .filters__group {
        margin-bottom: 15px;
        flex-grow: 0;

        @media (min-width: $media-md) and (max-width: $media-md-max) {
          width: 40vw;
        }

        .filters__filter,
        .group__label {
          text-transform: uppercase;
          font-size: $font-size-tiny;
          letter-spacing: 1px;
          white-space: nowrap;

          @media (min-width: $media-lg) {
            font-weight: $font-weight-black;
            color: $cool-gray;
          }
        }

        .group__label {
          border-bottom: solid 1px $cool-gray;
          line-height: 1.7;
          margin-bottom: .25em;

          @media (max-width: $media-md-max) {
            font-size: $font-size-tiny;
            max-width: 100px;
            font-weight: $font-weight-black;
            color: #929394;
          }
        }

        .filters__filter {
          color: $cool-gray;
          line-height: 2.73;
          cursor: pointer;
          text-overflow: ellipsis;
          margin-left: -5px;
          padding-right: 25px;

          @media (max-width: $media-md-max) {
            color: #919293;
            font-weight: $font-weight-bold;
            margin-top: 5px;
          }

          @media (min-width: 1001px) and (max-width: $media-xl) {
            min-width: 150px;
          }

          @media (max-width: 1000px) {
            min-width: 100px;
          }

          &:hover {
            color: $cobalt-3;
          }

          .filter__label {
            display: inline-block;
            padding: 2px 5px;
            line-height: 1.3em;
          }

          &--active .filter__label {
            background-color: #ccd3e9;
            color: $cobalt;
            border-radius: 1000px;
            display: inline-block;
            text-transform: uppercase;
            font-weight: $font-weight-medium;
            cursor: pointer;

            &:hover {
              background-color: $cobalt-2;
              color: $warm-gray;
            }
          }
        }
      }

      .filters__control {
        display: flex;
        justify-content: space-between;

        @media (max-width: $media-md-max) {
          margin-top: 25px;
          flex-direction: row;
          align-items: center;
          grid-column: 1 / span 2;
        }
        @media (min-width: $media-lg) {
          flex-direction: column;
          justify-content: space-between;
          flex-grow: 0;
          align-items: center;
          width: 105px;
          margin-left: auto;
        }
      }

      .filters__group--country {
        @media (min-width: $media-lg) {
          flex-grow: 1;
          margin-left: 25px;
          margin-right: 25px;
          max-width: 450px;

          .group__filters {
            columns: 2;
          }
        }

        @media (min-width: $media-xl) {
          margin-left: 50px;
          margin-right: 50px;
        }
      }

      .filters__group--topic {
        @media (min-width: $media-lg) {
          margin-right: 25px;
        }
      }

      .filters__group--topic {
        @media (min-width: $media-xl) {
          margin-right: 50px;
        }
      }
    }
  }

  .newsroom__search-bar.newsroom__search-bar--open {
    @media (max-width: $media-md-max) {
      .search__filters {
        transform: translateY(0);
      }
    }
  }

  .media__video {
    @media (max-width: $media-md-max) {
      .stagger-grid__item {
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }

  .search__heading {
    font-size: $font-size-medium;
    letter-spacing: 0.25px;
    margin-bottom: 0px;
    line-height: 1em;

    color: $navy;

    @media (min-width: $media-md) {
      font-size: $font-size-extra-extra-large;
      margin-top: -44px;
    }
  }

  .newsroom__order-bar {
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;

    .order-bar__label,
    .toggle__item {
      color: $luiza-d4;
      text-transform: uppercase;
      font-size: $font-size-tiny;
      letter-spacing: 1px;
      white-space: nowrap;
      font-weight: $font-weight-bold;
      margin: 0;
    }

    .order-bar__label {
      padding: 5px 0;
    }

    .order-bar__toggle {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;
    }

    .toggle__item {
      padding-left: 4px;
      padding-right: 4px;

      &:not(:last-child):after {
        display: inline-block;
        content: "|";
        padding-left: 8px;
      }

      span {
        cursor: pointer;
        padding: 5px 0;
      }


      span:hover,
      &--active span {
        color: $cobalt-2;
      }
    }
  }

  .newsroom__search-results {
    margin-top: 35px;
    min-height: 100px;
  }



  .featured-post__content {
    border-bottom: solid 1px $navy;
    padding: 25px 0;
  }



  .featured-post__image img {
    height: 100%;
    object-fit: cover;
  }

  .featured-post__content h2 {
    font-family: $font-family-sans-serif;
    font-size: 42px;
    font-weight: 300;
    color: $navy;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .resources {
    overflow: hidden;

    h2, h3 {
      margin-top: 0px;
    }

    .resources__media-kit {
      @media (min-width: $media-md) {
        border-right: solid 1px $navy
      }
    }

    .media-kit__intro {
      max-width: 400px;
    }

    .resources__asset p:last-child {
      margin-bottom: 15px;
    }

    .button {
      margin-bottom: 8px;
    }

    .accents {
      height: calc(100% + 400px);
      position: absolute;
      top: -200px;
      bottom: -200px;
      right: 0;
    }

    .accent {
      position: relative;
      height: 100%;
      transform: translate(calc(100% - 50px), 11%);
      top: 0;
      bottom: 0;
    }

    .accent--small {
      position: absolute;
      width: 86px;
      height: 86px;
    }

    @media (min-width: $media-md) {
      .accent--small {
        right: -15px;
        top: 50%;
        transform: translateY(180%);
      }

    }

    @media (max-width: $media-sm-max) {
      .accent--small {
        bottom: 50px;
        right: 0;
      }
    }
  }

  .footer {
    border-top: 0px;
  }
}

body.newsroom__index {
  @media (min-width: $media-lg) {
    h2 {
      font-size: $font-size-h1;
    }
  }
}

body.newsroom__post {
  h1 {
    margin-top: 10px;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-light;
    max-width: 917px;
    line-height: 1.2em;

    @media (max-width: $media-md-max) {
      font-size: 32px;
    }

    @media (min-width: $media-md) {
      font-size: 48px;
    }

    @media (min-width: $media-lg) {
      margin-bottom: 7px;
    }
  }

  cite,
  blockquote {
    font-family: $font-family-sans-serif;
    font-size: $font-size-extra-large;
    font-weight: $font-weight-light;
    text-transform: none;
    margin-bottom: 50px;

    p:first-child {
      margin-top: 0px;
    }
  }

  cite {
    line-height: 1.5em;
    font-size: $font-size-base;
    font-style: italic;
  }

  @media (min-width: $media-xl) {
    .post__grid {
      display: grid;
      grid-template-columns: 75px 228px 236px 100px 75px 2fr 75px;
    }
  }

  @media (min-width: $media-lg) {
    .post__grid {
      display: grid;
      grid-template-columns: 75px 1fr 1fr 100px 75px 2fr 75px;
    }
  }

  @media (max-width: $media-md-max) {
    .post__grid {
      display: flex;
      flex-direction: column;

      div {
        order: 1
      }

      aside {
        order: 2;
      }
    }
  }

  .hero .post__grid.container  {
    @media (min-width: $media-lg) {
      min-height: 440px;
    }
  }

  .hero__content {
    padding-top: 30px;
    grid-column: 2 / 7;
    min-height: 245px;
    display: flex;
    flex-direction: column;
    h1 {
      flex-grow: 1;
    }
  }

  .hero__footer {
    grid-column: 2 / 7;
    align-items: flex-end;
    padding-bottom: 30px;
  }

  .post__info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;

    .post__social {
      margin-top: 24px;
    }
  }

  @media (max-width: $media-md-max) {
    .hero__content {
      padding-bottom: 25px;
    }
  }


  &--has-video,
  &--has-image {
    .hero__footer {
      padding-left: 40px;

      @media (min-width: $media-md) {
        padding-top: 60px;
      }

      @media (max-width: $media-lg-max) {
        grid-column: 6 / 8;
      }

      @media (min-width: $media-xl) {
        grid-column: 6 / 7;
      }
    }

    .hero__content {
      @media (max-width: $media-md-max) {
        padding-bottom: 105px;
      }
    }
  }

  .post__meta {
    display: flex;

    text-transform: uppercase;
    color: $navy;
    font-size: $font-size-tiny;

    > div {
      white-space: nowrap;
      padding-right: 7px;
      position: relative;
    }

    > div:not(:first-child) {
      padding-left: 7px;
    }

    > div:not(:last-child):after {
      content: "";
      position: absolute;
      top: 5px;
      bottom: 5px;
      right: 0;
      width: 1px;
      background-color: $navy;
    }

    @media (min-width: $media-lg) {
      margin-top: 15px;
    }

    @media (max-width: $media-md-max) {
      margin-top: 40px;
    }
  }

  .post__info .post__author {
    font-size: $font-size-base;
    margin-bottom: 5px;
  }

  .post__info .share-icons {
    padding-left: 30px;
  }

  .post__related {
    @media (min-width: $media-lg) {
      margin-top: $spacing-xxl;
    }
  }

  .related__post {
    position: relative;
    padding-bottom: $spacing-s;
    margin-bottom: $spacing-s;

    h3 {
      font-weight: $font-weight-light;
      font-size: $font-size-extra-large;
      margin-top: 7px;
      margin-bottom: 15px;
      letter-spacing: .35px;

      @media (max-width: $media-md-max) {
        margin-top: 14px;
        margin-bottom: 14px;
      }
    }

    .post__link:hover {
      text-decoration: none;
      color: $cobalt;
    }
  }

  .related__post:not(:last-child):after {
    position: absolute;
    content: '';
    height: 1px;
    width: 100%;
    max-width: 228px;
    left: 0;
    bottom: 0;
    background-color: $black;
  }

  .post__date {
    font-weight: $font-weight-bold;
  }


  .post__body {
    @media (min-width: $media-lg) {
      margin-top: 50px;
    }
    margin-bottom: 25px;
  }

  .post__aside {
    grid-column: 2 / 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    @media (min-width: $media-lg) {
      margin-bottom: 25px;
    }
  }

  .post__aside:empty {
    display: none;
  }

  .post__footer {
    order: 2;
    border-top: 0px;

    .post__footer-inner {
      width: 100%;
      border-top: 1px solid #ECE8E7;
    }
  }

  .post__footer,
  .post__content {
    grid-column: 4 / 8;
    display: flex;
    justify-content: flex-end;

    > img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }

  .post__footer-inner,
  .post__content-inner {
    max-width: 612px;

    @media (min-width: $media-xxl) {
      max-width: 800px;
    }
  }

  .post__content:last-of-type .post__content-inner > p:last-child:after,
  .post__content:last-of-type .post__content-inner > ul:last-child > li:last-child > p:last-child:after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    background-image: url('~images/logo-icon.svg');
    background-size: 100% auto;
    transform: translate(7px, -1px);
  }

  .post__full-width {
    grid-column: 1 / 8;
  }

  .post__grid-header {
    grid-column: 1 / 6;
    position: relative;
    z-index: 1;
  }


  .post__grid-header__hook {
    @media (min-width: $media-lg) {
      margin-top: -170px;
    }
  }

  .post__excerpt {
    grid-column: 6 / 7;
    display: flex;
    align-items: center;
  }

  &--has-image .post__body,
  &--has-video .post__body {
    > .post__excerpt {
      @media (min-width: $media-lg) {
        padding-left: 35px;
      }
    }

    .post__grid-header__hook {
      @media (max-width: $media-md-max) {
        margin-top: -80px;
      }
    }
  }

  .post__excerpt blockquote {
    font-weight: $font-weight-semibold;
    font-size: 30px;
    line-height: 1.3em;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .post__author {
    text-transform: uppercase;
    font-weight: $font-weight-black;
    font-size: $font-size-tiny;
    letter-spacing: 1px;
  }

  .author__avatar {
    width: 100px;
  }

  .author__avatar h2 {
    font-size: $font-size-tiny;
    color: $navy;
    text-transform: uppercase;
    font-family: $font-family-sans-serif;
    margin-bottom: 8px;
  }

  .author__image {
    border-radius: 100%;
    object-fit: cover;
    object-position: center center;
    height: 100px;
    width: 100%;
  }

  .author__bio {
    font-size: $font-size-base;
    color: $navy;
  }


  .post__body--no-related .post__related,
  .post__body--no-accent .post__accent {
    display: none !important;
  }

  .post__accent {
    flex-grow: 1;
    position: relative;

    .accent {
      position: absolute;
      top: 0;
      right: 25%;
      bottom: 0;
      height: 100%;
      width: auto;
      max-width: 1000px;
    }
  }

  .post__block.post__block--fullWidthImage {
    padding: $spacing-xs 0 $spacing-m 0;
  }

  .post__social {
    display: flex;
    align-items: flex-end;


    @media (min-width: $media-lg) {
      padding-right: 30px;
    }
  }

  .author__info {
    position: relative;
    padding-bottom: 5px;

    @media (min-width: $media-md) and (max-width: $media-md-max) {
      max-width: 230px;
    }

    @media (min-width: $media-lg) {
      margin-top: 50px;
      max-width: 305px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 80%;
      background-color: $navy;

      @media (max-width: $media-md-max) {
        height: 2px;
        width: 100%;
      }
    }
  }

  .author__info--has-image {
    h2 {
      text-align: center;
    }
  }

  .language-links {
    margin-left: 75px;

    .button--link {
      color: $cobalt;
    }

    @media (max-width: $media-md-max) {
      margin-left: 0;
    }
  }


  .post__container {
    @media (min-width: $media-lg) {
      margin-bottom: 50px;
    }

    @media (max-width: $media-md-max) {
      padding-left: 30px;
      padding-right: 30px;
    }

  }
}

body.newsroom__research {
  .newsroom__hero {
    background-image: linear-gradient(to top, #ccaf64, #e5cd94);
  }

  .filters__group--type {
    pointer-events: none;
  }
}

body.newsroom__media {
  .newsroom__hero {
    background-color: #d9dff1;
  }

  @media (min-width: $media-md) {
    .newsroom__hero .hero__content {
      max-width: calc(100% - 532px);
      width: 24%;
      min-width: 300px;
    }

    .newsroom__hero .hero__footer {
      padding-left: 0;
    }
  }
}